var searchKeys = [{
  key: "ids",
  placeholder: "ID，多个一行一个",
  textarea: true,
  hidden: true
}, {
  key: "keyword",
  placeholder: "关键词查询",
  input: true
}, {
  key: "usernames",
  placeholder: "配置人username，多个一行一个",
  textarea: true,
  hidden: true
}, {
  key: "isstatus",
  placeholder: "状态",
  select: true,
  initialValue: 0
}, {
  key: "config_date",
  placeholder: ["配置时间_开始", "配置时间_结束"],
  dates: true,
  attrs: {
    valueFormat: "YYYY-MM-DD HH:mm:ss"
  }
}, {
  key: "button",
  button: true
}];
var tableColumns = [{
  title: "ID",
  dataIndex: "id",
  key: "id",
  width: 140,
  fixed: "left"
}, {
  title: "配置时间",
  dataIndex: "createtime",
  key: "createtime",
  width: 180,
  sorter: true,
  sortOrder: "descend"
}, {
  title: "推荐词",
  dataIndex: "keyword",
  key: "keyword",
  width: "100%"
}, {
  title: "状态",
  dataIndex: "isstatus",
  key: "isstatus",
  width: 100,
  scopedSlots: {
    customRender: "isstatus"
  }
}, {
  title: "配置人",
  dataIndex: "operator",
  key: "operator",
  width: 120
}, {
  title: "备注",
  dataIndex: "memo",
  key: "memo",
  width: 200,
  scopedSlots: {
    customRender: "memo"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  fixed: "right",
  width: 250,
  scopedSlots: {
    customRender: "action"
  }
}];
var tableColumns2 = [{
  title: "日志ID",
  dataIndex: "id",
  key: "id",
  width: 160
}, {
  title: "时间",
  dataIndex: "createtime",
  key: "createtime",
  width: 200
}, {
  title: "备注",
  dataIndex: "memo",
  key: "memo"
}];
export { searchKeys, tableColumns, tableColumns2 };